import React, { useEffect, useState } from "react";

const HomeSection = () => {
  const textSequence = [
    "Full Stack Web Developer",
    "UI/UX Designer",
    "Problem Solver",
  ];
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(false);

  // Function to handle text animation
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fading out
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % textSequence.length);
        setFade(false); // Start fading in
      }, 300); // Fade out duration
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, [textSequence.length]);

  return (
    <section
      id="home"
      className="min-h-screen flex flex-col items-center justify-center relative overflow-hidden"
    >
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-90"></div>
        <img
          src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="container flex-col flex items-center text-center justify-end mx-auto py-16 mt-24 z-10">
        <h1 className="home-heading text-6xl font-bold text-white animate-fade-in-down">
          Hi, I'm <span className="text-blue-300">Anant Singh</span>
        </h1>
        <div
          className={`typing-text-home text-2xl my-7 text-blue-100 h-8 transition-opacity duration-300 ${
            fade ? "opacity-0" : "opacity-100"
          }`}
          id="type-text"
        >
          {textSequence[index]}
        </div>
        <p className="home-paragraph text-xl mb-8 text-blue-100 max-w-2xl mx-auto">
          I create beautiful, responsive, and user-friendly web applications
          using cutting-edge technologies.
        </p>
        <div className="flex justify-center space-x-3 mb-12">
          {[
            {
              href: "https://youtube.com/@thedigitalworldofcoding",
              img: "/assets/images/youtube.png",
              alt: "YouTube",
            },
            {
              href: "https://github.com/thedwofcoding",
              img: "/assets/images/github.png",
              alt: "GitHub",
            },
            {
              href: "https://www.linkedin.com/in/anant-singh-9b30b5334",
              img: "/assets/images/linkedin.png",
              alt: "LinkedIn",
            },
            {
              href: "https://www.instagram.com/thedwofcoding/",
              img: "/assets/images/instagram.png",
              alt: "Instagram",
            },
            {
              href: "https://x.com/thedwofcoding",
              img: "/assets/images/x.png",
              alt: "X",
            },
          ].map(({ href, img, alt }) => (
            <a
              key={alt}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-blue-300 transition-colors"
            >
              <img src={img} className="w-9 h-9" alt={alt} />
            </a>
          ))}
        </div>
        <div className="flex justify-center">
          <a
            href="assets/pdfs/resume.pdf"
            download
            className="resume-btn bg-blue-500 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-600 transition-colors flex items-center"
          >
            <i
              className="fa-solid fa-download mr-2"
              style={{ marginBottom: "3.5px" }}
            ></i>
            Download CV
          </a>
        </div>
      </div>
      <div className="pb-14 animate-bounce">
        <a href="#about" className="text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </a>
      </div>
    </section>
  );
};

export default HomeSection;
