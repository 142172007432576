import React, { useEffect, useState } from "react";

const About = () => {
  const [aboutVisible, setAboutVisible] = useState(false);

  useEffect(() => {
    const aboutSection = document.querySelector(".about-section");

    const handleScroll = () => {
      const screenPos = window.innerHeight;
      const aboutPos = aboutSection.getBoundingClientRect().top;

      if (aboutPos < screenPos) {
        setAboutVisible(true);
      } else {
        setAboutVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="about" className="py-20 bg-white">
      <div className="container mx-auto text-center">
        <div
          className={`flex flex-col lg:flex-row items-center justify-center px-10 about-section ${
            aboutVisible ? "is-visible" : ""
          }`}
        >
          {/* Image Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="assets/images/my.jpg"
              alt="Anant Singh"
              className="about-image rounded-lg shadow-lg transition-transform transform hover:scale-105"
            />
          </div>

          {/* Text Section */}
          <div className="about-para lg:w-1/2 lg:pl-10 text-justify">
            <h2 className="about-heading text-4xl font-bold mb-8 text-blue-900">
              About Me
            </h2>
            <p className="text-base sm:text-lg mb-4 text-gray-700 leading-relaxed">
              Hello! I’m Anant Singh, an aspiring web developer. My journey
              began in 2019 when I first learned HTML through Blogger. Since
              then, I’ve expanded my skills in technologies like HTML, CSS,
              JavaScript, React, Node.js, Express, and Python.
            </p>
            <p className="text-base sm:text-lg mb-4 text-gray-700 leading-relaxed">
              I regularly work on personal projects to strengthen my skills and
              stay up-to-date with modern web development trends. Although I
              haven’t worked on professional projects yet, I’m focused on
              building a strong foundation.
            </p>
            <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
              I enjoy learning and tackling both frontend and backend
              challenges. In my free time, I stay informed about new
              technologies and industry trends.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
