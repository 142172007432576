import React, { useState, useEffect } from "react";
// import { Howl } from "howler";

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  // Scroll listener को useEffect में जोड़ें
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // जब भी window स्क्रोल हो, तब handleScroll function को कॉल करें
    window.addEventListener("scroll", handleScroll);

    // Cleanup function जो event listener को remove करेगा जब component अनमाउंट हो
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // जब बटन पर क्लिक हो, तब पेज को smooth तरीके से ऊपर ले जाएं
  const scrollToTop = () => {
    // new Howl({ src: ["/assets/audio/bottomTotop.wav"], volume: 1.0 }).play();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-up"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="18" y1="11" x2="12" y2="5"></line>
            <line x1="6" y1="11" x2="12" y2="5"></line>
          </svg>
        </button>
      )}
    </>
  );
};

export default BackToTopButton;
