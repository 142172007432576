import React, { useState, useEffect } from "react";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [fadeInLinks, setFadeInLinks] = useState(false);

  // स्क्रॉल इवेंट लिसनर जो हैडर के बैकग्राउंड को बदलता है
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // मोबाइल मेनू को टॉगल करने का फंक्शन
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // फेड-इन इफेक्ट को लागू करना
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeInLinks(true);
    }, 0); // 100 मिलीसेकंड का डिले

    return () => clearTimeout(timer);
  }, []);

  return (
    <header
      id="header"
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        scrollPosition > 50
          ? "bg-gradient-to-r from-blue-900 to-blue-500 shadow-lg"
          : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <a href="/" className="text-2xl font-bold text-white ml-2">
            Anant Singh
          </a>
        </div>

        {/* डेस्कटॉप मेनू */}
        <nav className="hidden lg:flex space-x-6 visible">
          {["Home", "About", "Skills", "Education", "FAQs", "Contact"].map(
            (item, index) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className={`text-white hover:text-blue-300 font-medium transition-opacity duration-500 ${
                  fadeInLinks ? "opacity-100" : "opacity-0"
                }`}
                style={{ transitionDelay: `${index * 200}ms` }} // डिले जोड़ें
              >
                {item}
              </a>
            )
          )}
        </nav>

        {/* मोबाइल मेनू टॉगल बटन */}
        <button
          id="menu-toggle"
          className="lg:hidden text-white"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* मोबाइल मेनू */}
      {menuOpen && (
        <div
          id="mobile-menu"
          className={`lg:hidden ${
            scrollPosition
              ? "bg-gradient-to-r from-blue-900 to-blue-500 shadow-lg"
              : `inset-0 bg-gradient-to-r from-blue-600 to-blue-800 opacity-95`
          } p-4 border-t`}
          style={{ height: "100vh" }}
        >
          {["Home", "About", "Skills", "Education", "FAQs", "Contact"].map(
            (item, index) => (
              <a
                key={item}
                href={`#${item.toLowerCase()}`}
                className={`flex items-center text-white py-2 font-medium transition-opacity duration-500 ${
                  fadeInLinks ? "opacity-100" : "opacity-0"
                }`}
                style={{ transitionDelay: `${index * 200}ms` }} // डिले जोड़ें
                onClick={toggleMenu}
              >
                {item}
              </a>
            )
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
