import React from "react";

const Footer = () => {
  return (
    <footer
      className="text-white py-8 w-full h-12 flex items-center justify-center"
      style={{ backgroundColor: "rgb(16, 39, 73)" }}
    >
      {/* Copyright Text */}
      <div
        className="text-center text-sm"
        style={{ color: "rgb(156 163 175 / 1)" }}
      >
        &copy; 2024 Anant Singh. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
