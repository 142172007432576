import React, { useState } from "react";
import Alert from "./Alert";
import { Howl } from "howler";

const ContactForm = () => {
  const [msg, setMsg] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const resetMsg = () => setMsg(null);

  const handleChange = (e) =>
    setData({ ...data, [e.target.id]: e.target.value });

  const submitForm = async () => {
    try {
      const res = await fetch("https://server-ashen-nu.vercel.app/emailer", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const result = await res.json();

      if (result.success) {
        setMsg(true);
        resetForm();
      } else {
        throw new Error("Submission error");
      }
    } catch {
      setMsg(false);
    }
  };

  const resetForm = () =>
    setData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    new Howl({ src: ["/assets/audio/mouse_click.mp3"], volume: 1.0 }).play();
    submitForm();
  };

  return (
    <section id="contact" className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="section-heading text-3xl font-bold text-blue-900">
            Get in touch
          </h2>
          <p className="text-gray-600 mt-4 max-w-xl mx-auto">
            We’d love to hear from you. Fill out the form below and we’ll get
            back to you shortly.
          </p>
        </div>

        <div className="contact-way grid grid-cols-2 gap-8 mb-12 justify-center">
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img
              src="assets/images/location.png"
              alt="Location"
              className="w-12 h-12 text-blue-600 mx-auto mb-4"
            />
            <h3 className="text-lg font-bold text-blue-800 mb-2">Address</h3>
            <p className="contact-info-val text-gray-600 font-medium">
              Shankar Park, West Sagarpur, New Delhi, Delhi 110046
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img
              src="assets/images/email.png"
              alt="Email"
              className="w-12 h-12 text-blue-600 mx-auto mb-4"
            />
            <h3 className="text-lg font-bold text-blue-800 mb-2">Email</h3>
            <p className="contact-info-val text-gray-600 font-medium">
              anant.singh7711@gmail.com
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl mx-auto">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-6 md:grid-cols-2"
          >
            {[
              ["firstName", "John"],
              ["lastName", "Doe"],
              ["email", "email@example.com"],
              ["phoneNumber", "+91 23XXXXXX76"],
              ["message", ""],
            ].map((field, index) => (
              <div
                key={index}
                className={`col-span-1 ${
                  field[0] === "message" ? "md:col-span-2" : ""
                }`}
              >
                <label htmlFor={field[0]} className="contact-placeholder block">
                  {[
                    field[0].replace(/([A-Z])/g, " $1")[0].toUpperCase(),
                    field[0]
                      .replace(/([A-Z])/g, " $1")
                      .slice(1)
                      .toLowerCase(),
                  ]}
                </label>
                {field[0] !== "message" ? (
                  <input
                    type={
                      field[0] === "email"
                        ? "email"
                        : field[0] === "phoneNumber"
                        ? "tel"
                        : "message"
                    }
                    id={field[0]}
                    className="contact-input w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder={`${field[1]}`}
                    value={data[field[0]]}
                    onChange={handleChange}
                  />
                ) : (
                  <textarea
                    id={field[0]}
                    rows="5"
                    className="contact-input w-full mt-2 p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    placeholder="Write your message here..."
                    value={data.message}
                    onChange={handleChange}
                  ></textarea>
                )}
              </div>
            ))}
            <div className="col-span-1 md:col-span-2 text-center">
              <button
                type="submit"
                className="submit-contact bg-blue-600 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-700 transition-all"
              >
                Send message
              </button>
            </div>
          </form>
        </div>
      </div>

      {typeof msg === "boolean" && <Alert status={msg} resetMD={resetMsg} />}
    </section>
  );
};

export default ContactForm;
