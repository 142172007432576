import React from "react";
import About from "./components/About";
import ContactForm from "./components/Contact";
import Education from "./components/Education";
import FAQSection from "./components/Faq";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomeSection from "./components/Home";
import BackToTopButton from "./components/ScrollToTopButton";
import Skills from "./components/Skills";

import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <Header />
      <HomeSection />
      <About />
      <Skills />
      <Education />
      <FAQSection />
      <ContactForm />
      <Footer />
      <BackToTopButton />
      <Analytics />
    </>
  );
}

export default App;
