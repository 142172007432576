import React, { useEffect, useRef, useState } from "react";

const Education = () => {
  const eduRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const eduBoxes = [
    {
      id: 1,
      image: "assets/images/12th.jpeg",
      title: "12th board is going on",
      institution:
        "Govt. Boys Sr. Sec. School No.2, Sagarpur, New Delhi, 2024-2025",
      description:
        "Learning and mastering a skill at a young age is not ordinary. This is not the time to waste, but to learn and become better. Work hard from now onwards, success will kiss your feet.",
    },
    {
      id: 2,
      image: "assets/images/self_study.jpeg",
      title: "Full-Stack Web Development",
      institution: "From the internet",
      description:
        "You can learn yourself! Big success with your hard work and gift, without any coding class or institute. Believe in yourself and move forward.",
    },
  ];

  const showEdu = () => {
    setIsVisible(true);
  };

  const hideEdu = () => {
    setIsVisible(false);
  };

  const handleScroll = () => {
    const screenPos = window.innerHeight;
    const eduPos = eduRef.current.getBoundingClientRect().top;

    if (eduPos < screenPos) {
      showEdu();
    } else {
      hideEdu();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="education" className="bg-white py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="section-heading text-3xl font-bold">Education</h2>
          <p className="text-gray-600 mt-4">
            My educational background and qualifications.
          </p>
        </div>

        <div
          ref={eduRef}
          className={`anim-edu grid grid-cols-1 md:grid-cols-2 gap-8 justify-center`}
        >
          {eduBoxes.map((box) => (
            <div
              key={box.id}
              className="edu-box bg-gray-50 p-6 rounded-md shadow-md"
              style={{
                opacity: isVisible ? "1" : "0",
                transition: "opacity 0.5s ease-in-out",
              }}
              data-dir={box.dataDir}
            >
              <img
                src={box.image}
                alt=""
                className="w-full rounded-lg mb-5"
                style={{ height: "17.67rem", border: ".7px solid #e3e3e3" }}
              />
              <h3 className="text-xl font-semibold">{box.title}</h3>
              <p className="text-gray-700 mt-2">{box.institution}</p>
              <p className="edu-des text-gray-600 mt-2">{box.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Education;
