import React, { useEffect, useRef, useState } from "react";

const Skills = () => {
  const skillPageRef = useRef(null);
  const skillBoxRefs = useRef([]);
  const [skillDone, setSkillDone] = useState(false);

  const skills = [
    {
      category: "Programming Languages",
      items: [
        {
          name: "HTML 5",
          progress: 95,
          img: "assets/svgs/html.svg",
          color:
            "linear-gradient(90deg, rgba(255, 111, 59, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "CSS 3",
          progress: 85,
          img: "assets/svgs/css.svg",
          color:
            "linear-gradient(90deg, rgba(43, 135, 255, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "ES6",
          progress: 75,
          img: "assets/svgs/js.svg",
          color:
            "linear-gradient(90deg, rgba(252, 227, 63, 1) 0%, rgba(78, 78, 78, 1) 100%)",
        },
        {
          name: "Python",
          progress: 60,
          img: "assets/svgs/python.svg",
          color:
            "linear-gradient(90deg, rgba(0, 94, 214, 1) 0%, rgba(255, 225, 103, 1) 100%)",
        },
        {
          name: "C",
          progress: 40,
          img: "assets/svgs/c.svg",
          color:
            "linear-gradient(90deg, rgba(66, 63, 252, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "PHP",
          progress: 30,
          img: "assets/svgs/php.svg",
          color:
            "linear-gradient(90deg, rgba(165, 187, 255, 1) 0%, rgba(129, 129, 129, 1) 100%)",
        },
      ],
    },
    {
      category: "Frameworks & Libraries",
      items: [
        {
          name: "React JS",
          progress: 87,
          img: "assets/svgs/reactJS.svg",
          color:
            "linear-gradient(90deg, rgba(89, 208, 255, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Node JS",
          progress: 80,
          img: "assets/svgs/nodeJS.svg",
          color:
            "linear-gradient(90deg, rgba(13, 158, 42, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Express JS",
          progress: 78,
          img: "assets/svgs/expressJS.svg",
          color:
            "linear-gradient(90deg, rgba(56, 59, 57, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Tailwind",
          progress: 85,
          img: "assets/svgs/tailwind.svg",
          color:
            "linear-gradient(90deg, rgba(8, 196, 236, 1) 0%, rgba(86, 125, 161, 1) 100%)",
        },
        {
          name: "Django",
          progress: 67,
          img: "assets/images/django.png",
          color:
            "linear-gradient(90deg, rgba(6, 46, 14, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Flask",
          progress: 78,
          img: "assets/svgs/flask.svg",
          color:
            "linear-gradient(90deg, rgba(61, 64, 62, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
      ],
    },
    {
      category: "Others",
      items: [
        {
          name: "Git",
          progress: 75,
          img: "assets/svgs/git.svg",
          color:
            "linear-gradient(90deg, rgba(255, 85, 33, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "GitHub",
          progress: 75,
          img: "assets/svgs/github.svg",
          color:
            "linear-gradient(90deg, rgba(8, 8, 8, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Firebase",
          progress: 45,
          img: "assets/images/firebase.png",
          color:
            "linear-gradient(90deg, rgba(255, 192, 0, 1) 0%, rgba(254, 255, 0, 1) 100%)",
        },
        {
          name: "MongoDB",
          progress: 65,
          img: "assets/svgs/mongodb.svg",
          color:
            "linear-gradient(90deg, rgba(67, 168, 27, 1) 0%, rgba(76, 224, 17, 1) 100%)",
        },
        {
          name: "Google Cloud",
          progress: 55,
          img: "assets/svgs/gCloud.svg",
          color:
            "linear-gradient(90deg, rgba(36, 127, 255, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
        {
          name: "Sass",
          progress: 95,
          img: "assets/svgs/sass.svg",
          color:
            "linear-gradient(90deg, rgba(255, 94, 185, 1) 0%, rgba(237, 237, 237, 1) 100%)",
        },
      ],
    },
  ];

  const animatePercentage = (bar, targetPercentage, seconds) => {
    let currentPercentage = 0;
    const totalSteps = targetPercentage;
    const intervalTime = (seconds * 1000) / totalSteps;

    const interval = setInterval(() => {
      if (currentPercentage < targetPercentage) {
        currentPercentage++;
        bar.innerText = `${currentPercentage}%`;
      } else {
        clearInterval(interval);
      }
    }, intervalTime);
  };

  const showProgress = () => {
    if (!skillDone) {
      skillBoxRefs.current.forEach((box) => {
        box.style.marginTop = "0";
      });

      skills.forEach((skillCategory, catIndex) => {
        skillCategory.items.forEach((item, index) => {
          const progressBar =
            skillBoxRefs.current[catIndex].querySelectorAll(".progress-bar")[
              index
            ];
          const targetPercentage = item.progress;

          progressBar.style.width = `${targetPercentage}%`;
          progressBar.style.background = item.color; // सही रंग सेट करें
          animatePercentage(
            skillBoxRefs.current[catIndex].querySelectorAll(
              ".skill-persentage"
            )[index],
            targetPercentage,
            2
          );
        });
      });

      setSkillDone(true);
    }
  };

  const hideProgress = () => {
    skillBoxRefs.current.forEach((box) => {
      box.style.marginTop = "100px";
    });

    skills.forEach((skillCategory, catIndex) => {
      skillCategory.items.forEach((_, index) => {
        const progressBar =
          skillBoxRefs.current[catIndex].querySelectorAll(".progress-bar")[
            index
          ];
        progressBar.style.width = `${0}%`;
        skillBoxRefs.current[catIndex].querySelectorAll(".skill-persentage")[
          index
        ].innerText = `${0}%`;
      });
    });

    setSkillDone(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const screenPos = window.innerHeight;
      const skillPagePos = skillPageRef.current.getBoundingClientRect().top;

      if (skillPagePos < screenPos) {
        showProgress();
      } else {
        hideProgress();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [skillDone]);

  return (
    <section id="skills" className="bg-gray-100 py-16" ref={skillPageRef}>
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="section-heading text-3xl font-bold">Skills</h2>
          <p className="text-gray-600 mt-4">
            My technical skills and expertise.
          </p>
        </div>

        <div className="main-skill-cover grid-cols-3 gap-5 grid grid-cols-1">
          {skills.map((skillCategory, catIndex) => (
            <div
              className="skill-box bg-white p-6 rounded-md shadow-md"
              key={catIndex}
              ref={(el) => (skillBoxRefs.current[catIndex] = el)}
            >
              <h3 className="skill-cat text-xl font-semibold mb-4">
                {skillCategory.category}
              </h3>
              {skillCategory.items.map((item, index) => (
                <div className="skill-progress mb-4" key={index}>
                  <div className="flex items-center justify-between mb-2">
                    <div className="content-0 flex items-center">
                      <img src={item.img} alt={item.name} className="w-7 h-7" />
                      <span className="ml-3 text-sm font-semibold text-gray-800">
                        {item.name}
                      </span>
                    </div>

                    <span
                      className="skill-persentage w-8 h-5 font-bold text-white shadow-lg text-center py-1"
                      style={{
                        fontSize: "9px",
                        borderRadius: "4px",
                        backgroundColor: "#0e2545",
                      }}
                    >
                      0%
                    </span>
                  </div>
                  <div className="relative w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="absolute left-0 top-0 h-4 rounded-full"
                      style={{ width: "90%" }}
                    >
                      <div
                        className="progress-bar h-1.5 rounded-sm"
                        data-progress={item.progress}
                        style={{ width: "0%", background: item.color }}
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
