import React, { useState, useEffect } from "react";
import { Howl } from "howler";

function Alert({ status, resetMD }) {
  const [mD, setMD] = useState(true);

  const closeAlert = () => {
    setMD(false);
    resetMD();
  };

  useEffect(() => {
    setMD(true);

    function soundPlayer() {
      if (status) {
        const sound = new Howl({
          src: ["/assets/audio/success_message.mp3"],
          volume: 1.0,
        });
        sound.play();
      } else {
        const sound = new Howl({
          src: ["/assets/audio/error_message.mp3"],
          volume: 1.0,
        });
        sound.play();
      }
    }
    soundPlayer();
  }, [status]);

  return (
    <>
      {status
        ? mD && (
            <div
              className="alert-box flex items-center justify-between fixed left-1/2 top-[17%] transform -translate-x-1/2 w-[90%] max-w-[400px] h-[50px] px-5 rounded-lg bg-white z-40 font-semibold text-[15px] border-2 border-blue-500 shadow-lg"
              style={{ backgroundColor: "#fff" }}
            >
              {/* Icon box */}
              <div className="icon-alert-box bg-blue-500">
                <i className="fa-solid fa-check text-white"></i>
              </div>

              {/* Message */}
              <p className="message mx-2 flex-1 text-center">
                The message has been sent.
              </p>

              {/* Close Icon */}
              <i
                className="close text-base fa-solid fa-xmark text-blue-500 cursor-pointer"
                onClick={closeAlert}
              ></i>
            </div>
          )
        : mD && (
            <div
              className="alert-box flex items-center justify-between fixed left-1/2 top-[17%] transform -translate-x-1/2 w-[90%] max-w-[400px] h-[50px] px-5 rounded-lg bg-white z-40 font-semibold text-[15px] border-2 border-red-600 shadow-lg"
              style={{ backgroundColor: "#fff" }}
            >
              {/* Icon box */}
              <div className="icon-alert-box bg-red-600">
                <i className="fa-solid fa-xmark text-white"></i>
              </div>

              {/* Message */}
              <div className="message mx-2 flex-1 text-center">
                Message has not been sent.
              </div>

              {/* Close Icon */}
              <i
                className="close text-base fa-solid fa-xmark text-red-600 cursor-pointer"
                onClick={closeAlert}
              ></i>
            </div>
          )}
    </>
  );
}

export default Alert;
