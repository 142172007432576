import React, { useState } from "react";

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  const faqs = [
    {
      id: 1,
      question: "Why should you hire me?",
      answer:
        "You should hire me because I possess the skills, passion, and dedication to deliver high-quality web development solutions. I am constantly learning and adapting to new technologies, which makes me a strong candidate who can bring fresh ideas and solutions to your projects.",
    },
    {
      id: 2,
      question: "How can I help you?",
      answer:
        "I can help you by designing and developing efficient, responsive, and user-friendly websites that meet your business needs. I focus on clean, scalable code and ensure that every project aligns with industry best practices for security, performance, and accessibility.",
    },
    {
      id: 3,
      question: "How can I contribute to group work?",
      answer:
        "I believe in strong collaboration and communication within teams. I am not only capable of completing individual tasks but also work well with others to solve problems collectively. My ability to listen and contribute meaningful ideas helps in achieving the team's objectives efficiently.",
    },
    {
      id: 4,
      question: "Why am I eager to learn new tech?",
      answer:
        "I am passionate about web development and understand that the tech landscape is constantly evolving. By learning new technologies, I stay ahead of the curve and ensure that my skills remain relevant. It also excites me to explore innovative tools that can enhance my productivity and the quality of my work.",
    },
    {
      id: 5,
      question: "What are my main web dev skills?",
      answer:
        "My core skills include front-end technologies like HTML, CSS, JavaScript, React, and TailwindCSS. I am also proficient in back-end development using Node.js and Express, and I have a solid understanding of databases like MongoDB and MySQL. These skills allow me to create dynamic, full-stack web applications.",
    },
    {
      id: 6,
      question: "How can I tackle challenges in web dev?",
      answer:
        "I approach problem-solving by breaking the problem down into smaller, manageable parts. I research possible solutions, analyze their pros and cons, and implement the one that best fits the situation. I also test and iterate to ensure the solution works effectively in various scenarios.",
    },
  ];

  return (
    <div className="max-w-6xl mx-auto p-6" id="faqs">
      <h1 className="section-heading text-3xl font-bold text-center text-gray-800 mb-8">
        FAQs - Web Development
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {faqs.map(({ id, question, answer }) => (
          <div
            key={id}
            className={`px-4 py-3.5 bg-white rounded-lg shadow-md transition-all duration-500 ease-in-out faq-box ${
              openFAQ === id ? "expanded" : ""
            }`}
            onClick={() => toggleFAQ(id)}
          >
            <div className="flex justify-between items-center cursor-pointer">
              <h2 className="faq text-sm font-semibold">{question}</h2>
              <i
                className={`fas fa-chevron-down text-gray-600 ${
                  openFAQ === id ? "transform rotate-180" : ""
                }`}
              ></i>
            </div>
            <p className="faq-p text-justify text-gray-600 text-sm mt-2">
              {answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
